import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


function ConfirmDelete(){
    const [response, setResponse] = useState("");
    const navigate = useNavigate();

    const deleteFun = async (Id) => {
        // e.preventDefault();

        try {
            const res = await axios.request({
                method: "DELETE",
                url: "https://messaging.exceptronsolutions.com/scan.php/users",
                data: {
                    id: Id, // Send id in the request body
                },
            });  
            setResponse(res.data.message || "Success!");
            navigate('/');

        } catch (error) {
            setResponse(error.response?.data?.error || "Error occurred");
        }
    };
    const id = localStorage.getItem('setid');
    return(
        <div>
        <h1>Confirm Delete?</h1>
        <button onClick={() => deleteFun(id)}>Yes Delete</button>
        </div>
    )
}

export default ConfirmDelete;