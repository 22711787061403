import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function CreateUser () {
  const [name, setName] = useState("");
  const [response, setResponse] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://messaging.exceptronsolutions.com/scan.php/users", {
        name: name,
      });
      setResponse(res.data.message || "Success!");
      navigate('/');
    } catch (error) {
      setResponse(error.response?.data?.error || "Error occurred");
    }
  };

  return (
    <div>
      <h1>Create User</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
      <p>{response}</p>
    </div>
  );
};

export default CreateUser;
