import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function UpdateUser(){
    const [name, setName] = useState("");
    const [response, setResponse] = useState("");
    const navigate = useNavigate();


    const id = localStorage.getItem('setid');
    const getname = localStorage.getItem('setname');

    useEffect(() => {
        if(getname){
            setName(getname);
        }
    }, [getname]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await axios.put("https://messaging.exceptronsolutions.com/scan.php/users", {
            id: id,
            name: name
          });
          setResponse(res.data.message || "Success!");
          navigate('/');
        } catch (error) {
          setResponse(error.response?.data?.error || "Error occurred");
        }
      };

    return(
        <div>
            <h1>Update User</h1>
            <form onSubmit={handleSubmit}>
                <input
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                />
                <button type="submit">Update</button>
            </form>
            <p>{response}</p>
        </div>
    )
};

export default UpdateUser;