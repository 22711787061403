import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function ListUser(){
    const [users, setUsers] = useState([]);
    const [response, setResponse] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch users on component mount
        axios.get("https://messaging.exceptronsolutions.com/scan.php/users")
        .then(res => {
            setUsers(res.data);
        })
        .catch(error => {
            console.error("There was an error fetching the users:", error);
        });
    }, []);

    const deleteFun = async (Id) => {
        // e.preventDefault();
        localStorage.setItem('setid', Id);

        navigate('/confirm-delete');
        // try {
        //     const res = await axios.request({
        //         method: "DELETE",
        //         url: "https://messaging.exceptronsolutions.com/scan.php/users",
        //         data: {
        //             id: Id, // Send id in the request body
        //         },
        //     });  
        //     setResponse(res.data.message || "Success!");
        //     window.location.reload();
        // } catch (error) {
        //     setResponse(error.response?.data?.error || "Error occurred");
        // }
    };
    
    const updateFun = async (Id, Name) => {
        localStorage.setItem('setid', Id);
        localStorage.setItem('setname', Name);

        navigate('/update-user');
    };
    const style = {
        textDecoration: "none",
        background: "none"
    };
    return(
        <div>
        <h2>Users List</h2>
        <table border="1px solid" className="table table-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
            </tr>
            {users.map(user => (
                <tr>
                    <td style={style}>{user.Name}</td>
                    <td><a href="" style={style} onClick={() => updateFun(user.Id, user.Name)}>Update</a></td>
                    <td><a href="" style={style} onClick={() => deleteFun(user.Id)}>Delete</a></td>
                </tr>
            ))}
        </table>
        <p>{response}</p>
        </div>
    )
}

export default ListUser;